module.exports = [{
      plugin: require('/opt/build/repo/web/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kickbush Dev","short_name":"KD","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/gatsby-icon.png"},
    },{
      plugin: require('/opt/build/repo/web/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"cacheId":"bbk-offline"},
    },{
      plugin: require('/opt/build/repo/web/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
